import '../styles/app.scss';
import '../styles/highlight.css';
import hljs from 'highlight.js';

let searchForm: any = document.getElementById('search-form');

searchForm.onsubmit = async function(e: Event) {
  // stop the regular form submission
  e.preventDefault();
  runSearch();
};

document.getElementById('ec-query')?.addEventListener('paste', (e: Event) => runSearch());

async function runSearch() {
  let fd: any = {};
  new FormData(searchForm).forEach((value, key: any) => {
    fd[key] = value;
  });

  try {
    const SOQueryTemplate = `https://api.stackexchange.com/2.2/search/advanced?order=desc&sort=relevance&answers=1&filter=withbody&site=stackoverflow&key=Nq4apLl7jpfWZJUxaHLCnw%28%28&q=`;

    let data = await fetch(SOQueryTemplate + encodeURIComponent(fd.text.split(/\W/).join(' OR ')));
    let questions = (await data.json()).items;

    questions = questions.map((question: any) => ({
      title: question.title,
      link: question.link,
      id: question.question_id,
      body: question.body,
      owner: question.owner,
    }));

    showQuestions(questions);
  } catch (e) {
    console.log(e);
    const templateDiv = document.getElementById('templates')!;
    let questionsDiv = document.getElementById('stackex-questions')!;
    let errorDiv = templateDiv.getElementsByClassName('error')[0].cloneNode(true);
    errorDiv.textContent = e.message;
    while (questionsDiv.hasChildNodes()) {
      questionsDiv.removeChild(questionsDiv.lastChild!);
    }
    questionsDiv.appendChild(errorDiv);
  }
}

// focus the search bar
document.getElementById('muse-query')!.focus();

function showQuestions(questions: Array<any>, initial?: boolean) {
  const templateDiv = document.getElementById('templates')!;
  const questionLinkTemplate = templateDiv.getElementsByClassName('stackex-question')[0];

  let questionsDiv = document.getElementById('stackex-questions')!;
  // remove all existing questions, we got a new query
  while (questionsDiv.hasChildNodes()) {
    questionsDiv.removeChild(questionsDiv.lastChild!);
  }

  if (!initial && questions.length === 0) {
    questionsDiv.appendChild(templateDiv.getElementsByClassName('no-results')[0].cloneNode(true));
  }

  // iterate over questions and render them
  console.log(questions);
  questions.map(question => {
    const questionDetails: HTMLElement = <HTMLElement>questionLinkTemplate.cloneNode(true);
    //questionDetails.getElementsByClassName('stackex-post-body')[0].innerHTML = question['body'];
    questionDetails.getElementsByClassName('stackex-question-title')[0].textContent =
      question['title'];
    questionDetails.getElementsByClassName('query-headline')[0].innerHTML = question['body'].substr(
      0,
      400
    );

    questionDetails
      .getElementsByClassName('stackex-question-title')[0]
      .setAttribute('href', question['link']);

    questionsDiv.appendChild(questionDetails);
    handleSnippets(questionDetails);
  });
}

// Easy copy / paste on pre elements
function handleSnippets(node: HTMLElement) {
  Array.from(node.getElementsByTagName('pre')).forEach(block => {
    hljs.highlightBlock(block);
  });
}
